import React from "react"
import Layout from "../components/layout"
import {withPrefix} from "gatsby";
import PreLoader from "../components/preLoader";
import MainMenu from "../components/mainMenu";
import $ from "jquery";
import AOS from "aos";

class Home extends React.Component {

    // componentDidMount is the right place
    async componentDidMount() {

        // init popover
        $(() => {
            if ($("[data-aos]").length) {
                AOS.init({
                    duration: 1000,
                    mirror: true
                });
            }

        });

    }

    render() {
        return (<Layout pageTitle="Training Centre Manager">

        
            <div className="hero-banner-four">
                <img src="images/shape/100.svg" alt="" className="shapes shape-four"/>
                <img src="images/shape/101.svg" alt="" className="shapes shape-five"/>
                <img src="images/shape/102.svg" alt="" className="shapes shape-six"/>
                <img src="images/shape/103.svg" alt="" className="shapes shape-seven"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-last">
                            <div className="illustration-holder">
                                <img src="images/assets/ils_14.svg" alt="" className="illustration"/>
                                <img src="images/assets/ils_14.1.svg" alt=""
                                     className="shapes shape-one"/>
                                <img src="images/assets/ils_14.2.svg" alt=""
                                     className="shapes shape-two"/>
                                <img src="images/assets/ils_14.3.svg" alt=""
                                     className="shapes shape-three"/>
                            </div>
                            <p className="review-text">Over <span>150,000+ client</span> all over the world.
                            </p>
                        </div>
                        <div className="col-lg-6 order-lg-first">
                            <div className="text-wrapper">
                                <h1><span>Training</span> centre manager.</h1>
                                <p className="sub-text">One solution to manage candidates, awards and automate reminders.</p>
                                <a href="/features" className="theme-btn-five">Features</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fancy-feature-eight mt-160 md-mt-100">
                    <div className="container">
                        <div className="bg-wrapper">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6" data-aos="fade-up"
                                     data-aos-duration="1200">
                                    <div className="block-style-thirteen">
                                        <div className="icon"><img src="images/icon/60.svg" alt=""/></div>
                                        <div className="title font-rubik">Smart popups</div>
                                        <p>Create customized popups and show the message at the
                                            lorem</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up"
                                     data-aos-duration="1200" data-aos-delay="100">
                                    <div className="block-style-thirteen style-border">
                                        <div className="icon"><img src="images/icon/61.svg" alt=""/></div>
                                        <div className="title font-rubik">Embeded Forms</div>
                                        <p>Collect website leads with embedded forms and integrate
                                            easily.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up"
                                     data-aos-duration="1200" data-aos-delay="200">
                                    <div className="block-style-thirteen">
                                        <div className="icon"><img src="images/icon/62.svg" alt=""/></div>
                                        <div className="title font-rubik">Autoresponder</div>
                                        <p>Send welcome email to your new subscribers with a code.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
        )
    }
}

export default Home;